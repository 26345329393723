/**
 * Test IDs for the call complete card.
 */
export const CallCompleteCard = {
  /**
   * Test ID for the complete button.
   * This completes the call in wrap up state.
   */
  COMPLETE_BUTTON: "call-complete-card-complete-button",
};

/**
 * Test ID for the call control live card.
 */
export const CallControlLiveCard = {
  /**
   * Test ID for the mute button.
   * This is the button the user clicks to mute/unmute the call.
   */
  MUTE_BUTTON: "call-control-live-card-mute-button",
  /**
   * Test ID for the end call button.
   * This is the button the user clicks to end the call.
   */
  END_BUTTON: "call-control-live-card-end-call-button",
};

/**
 * Test ID for the call control ringing card.
 */
export const CallControlRingingCard = {
  /**
   * Test ID for the accept button.
   * This is the button the user clicks to accept an incoming call.
   */
  ACCEPT_BUTTON: "call-control-ringing-card-accept-button",
  /**
   * Test ID for the call direction.
   *
   * @example
   * Incoming Call
   *
   * @example
   * Outgoing Call
   */
  CALL_DIRECTION: "call-control-ringing-card",
  /**
   * Test ID for the hang up button.
   * This is the button the user clicks to cancel/reject an incoming/outgoing call.
   */
  HANG_UP_BUTTON: "call-control-ringing-card-hang-up-button",
};

/**
 * Test ID for the dialer component.
 */
export const Dialer = {
  /**
   * Test ID for the dialer input box.
   * This is where the user enters the phone number.
   */
  INPUT_BOX: "dialer-input-box",
  /**
   * Test ID for the call button.
   * This is the button the user clicks to make a call.
   */
  CALL_BUTTON: "dialer-call-button",
};

/**
 * Test ID for the header component.
 */
export const Header = {
  /**
   * Test ID for the dialer button.
   * This shows the dialer component.
   */
  DIALER_BUTTON: "header-dialer-button",
  /**
   * Test ID for the user availability status selector.
   * This is where the user can change their availability status.
   */
  USER_AVAILABILITY_STATUS_SELECTOR: "header-availability-status-selector",
};

/**
 * Test IDs for the sidebar component.
 */
export const Sidebar = {
  /**
   * Test ID for the offline users collapsible section in the sidebar.
   */
  OFFLINE_USERS_section: "sidebar-offline-users-section",
  /**
   * Test ID for the online users collapsible section in the sidebar.
   */
  ONLINE_USERS_SECTION: "sidebar-online-users-section",
  /**
   * Test ID for the user component in the sidebar.
   */
  USER: "sidebar-user",
  /**
   * Test ID for the user name in the sidebar.
   */
  USER_NAME: "sidebar-user-name",
  /**
   * Test ID for the user availability status in the sidebar.
   */
  USER_AVAILABILITY_STATUS: "sidebar-user-availability-status",
  /**
   * Test ID for the user caller names in the sidebar.
   *
   * This is a comma delimited list of the caller names under the user card.
   */
  USER_CALLS_CONTACT_NAMES: "sidebar-user-caller-names",
};
