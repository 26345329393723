"use client";

/**
 * Third-party libraries.
 */
import { Collapse, CollapseProps, Skeleton } from "antd";
import dayjs from "dayjs";
import { HTMLAttributes, useMemo, useState } from "react";

/**
 * Project components.
 */
import { UserAvatar } from "@/components/client/avatar";
import { UserAvailabilityStatus } from "@/components/client/graphql";
import { ChevronDown, ChevronRight } from "@/components/client/images";
import { TimeUtility } from "@/components/common/time";
import { formatDate, formatDuration } from "@/components/common/time/utilities/time-utility";
import { StringUtility } from "@/components/common/utilities";
import { PlayWrightTestId } from "@/tests/constants";
import { useInterval } from "../hooks/use-interval";

// =============================================================================
// User
// =============================================================================

/**
 * Properties of the User component.
 */
type UserProps = {
  calls: {
    /**
     * Customer name or phone number.
     */
    customer: string;
  }[];
  /**
   * Unique identifier of the User.
   */
  id: string;
  /**
   * Name of the User.
   */
  name: string;
  /**
   * Availability status of the user.
   */
  status: UserAvailabilityStatus;
  /**
   * Date and time the status was updated.
   */
  statusDateTime: Date;
};

/**
 * Displays a user with their availability status and time in status.
 */
const User = ({
  calls,
  name,
  status,
  statusDateTime
}: UserProps) => {
  // ===========================================================================
  // ===========================================================================
  // States
  // ===========================================================================
  // ===========================================================================

  const [timeInStatus, setTimeInStatus] = useState<string>(TimeUtility.formatDuration({
    from: statusDateTime,
    to: new Date(),
    format: "numeric"
  }));

  // ===========================================================================
  // ===========================================================================
  // Variables
  // ===========================================================================
  // ===========================================================================

  /**
   * User availability status display.
   * Converts the underlying status to a human-readable format.
   */
  const userAvailabilityStatus = useMemo(() => {
    switch (status) {
      case UserAvailabilityStatus.Available:
        return "Available";
      case UserAvailabilityStatus.Break:
        return "Break";
      case UserAvailabilityStatus.Busy:
        return "Busy";
      case UserAvailabilityStatus.Offline:
        return "Offline";
      case UserAvailabilityStatus.OnACall:
        return "On Call";
      case UserAvailabilityStatus.Ringing:
        return "Ringing";
      case UserAvailabilityStatus.WrappingUp:
        return "Wrapping Up";
      default:
        return "Unknown";
    }
  }, [status]);

  // ===========================================================================
  // ===========================================================================
  // Effects
  // ===========================================================================
  // ===========================================================================

  useInterval(() => {
    const today = dayjs().startOf("day").toDate();
    const yesterday = dayjs().subtract(1, "day").startOf("day").toDate();

    // 1. Older dates e.g. "Since MM/DD/YYYY, 10:34AM" (When: current date < yesterday)
    if (statusDateTime < yesterday) {
      setTimeInStatus(`Since ${formatDate(statusDateTime, {
        format: "DD MMM"
      })}`);
      return;
    }

    // 2. Since Yesterday (When: yesterday < current date < today)
    if (statusDateTime < today && statusDateTime > yesterday) {
      setTimeInStatus(`Since Yesterday`);
      return;
    }

    // 3. Everything else
    setTimeInStatus(formatDuration({
      from: statusDateTime,
      to: new Date(),
      format: "numeric"
    }));
  }, 1000);
  return <div className="flex animate-slide-left items-center gap-2" data-testid={PlayWrightTestId.Sidebar.USER} data-sentry-component="User" data-sentry-source-file="user-list.tsx">
      <UserAvatar status={status} initials={StringUtility.getInitials({
      input: name,
      maxLength: 2
    }) ?? ""} data-sentry-element="UserAvatar" data-sentry-source-file="user-list.tsx" />
      <div className="inline-flex flex-1 flex-col items-start justify-center gap-1">
        <div className="self-stretch text-sm font-semibold leading-[17.50px] text-tpl-navy" data-testid={PlayWrightTestId.Sidebar.USER_NAME}>
          {name}
        </div>
        <div className="self-stretch text-xs font-semibold leading-none text-gray-400" data-testid={PlayWrightTestId.Sidebar.USER_AVAILABILITY_STATUS}>
          {userAvailabilityStatus} | {timeInStatus}
        </div>
        <div className="self-stretch text-xs font-semibold leading-none text-gray-400" data-testid={PlayWrightTestId.Sidebar.USER_CALLS_CONTACT_NAMES}>
          {calls.map(call => call.customer).join(", ")}
        </div>
      </div>
    </div>;
};

// =============================================================================
// User List Group
// =============================================================================

/**
 * Properties of the AgentListGroup component.
 */
type UserListGroupProps = {
  /**
   * List of users.
   */
  users: UserProps[];
  /**
   * Default state of the group.
   */
  defaultExpanded?: boolean;
  /**
   * Group label.
   */
  label: string;
  /**
   * Loading state.
   * Displays a skeleton when true.
   */
  loading?: boolean;
} & CollapseProps;

/**
 * Displays a group of users.
 * This group has a group label on top of the list.
 */
function UserListGroup({
  users,
  defaultExpanded = true,
  label,
  loading,
  ...rest
}: UserListGroupProps) {
  const [expand, setExpand] = useState<boolean>(defaultExpanded);
  const items: CollapseProps["items"] = [{
    key: "1",
    label: <div className="sticky top-0 z-10 my-1 flex w-full cursor-pointer items-center justify-between bg-white" onClick={() => {
      setExpand(!expand);
    }}>
          <h4 className="mx-4! !m-0 text-tpl-navy-light">{label}</h4>
          {expand ? <ChevronDown className="h-4 w-4 text-tpl-navy-light" /> : <ChevronRight className="h-4 w-4 text-tpl-navy-light" />}
        </div>,
    children: <div className="flex h-full w-full flex-col gap-3 leading-7">
          {loading && <div className="flex gap-2">
              <div>
                <Skeleton.Avatar size="large" active />
              </div>
              <div className="flex flex-col">
                <Skeleton.Input size="small" active />
                <Skeleton.Input size="small" active />
              </div>
            </div>}
          {!loading && <div className={`flex flex-col gap-3 overflow-hidden`}>
              {users.map(user => <User calls={user.calls} id={user.id} key={user.name} name={user.name} status={user.status} statusDateTime={user.statusDateTime} />)}
            </div>}
        </div>,
    showArrow: false,
    style: {
      padding: 0
    }
  }];
  return <Collapse defaultActiveKey={defaultExpanded ? 1 : undefined} ghost items={items} {...rest} data-sentry-element="Collapse" data-sentry-component="UserListGroup" data-sentry-source-file="user-list.tsx"></Collapse>;
}

// =============================================================================
// User List
// =============================================================================

/**
 * Properties of the UserList component.
 */
export type UserListProps = {
  /**
   * The users to display.
   */
  users: UserProps[];
  /**
   * Indicates that the list is loading.
   */
  loading?: boolean;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Displays a user list with their availability status.
 *
 * This displays two groups of users, one (1) group for online users, and
 * another group for offline users.
 */
export function UserList({
  users,
  loading,
  ...rest
}: UserListProps) {
  /**
   * Users who are not in "Offline" status.
   */
  const onlineUser = useMemo(() => users?.filter(user => user.status !== UserAvailabilityStatus.Offline), [users]);

  /**
   * Users who are in "Offline" status.
   */
  const offlineUsers = useMemo(() => users?.filter(user => user.status === UserAvailabilityStatus.Offline), [users]);
  return <div className="flex w-full flex-col gap-3" {...rest} data-sentry-component="UserList" data-sentry-source-file="user-list.tsx">
      <UserListGroup users={onlineUser} data-testid={PlayWrightTestId.Sidebar.ONLINE_USERS_SECTION} label={`Active Users (${onlineUser.length}/${users?.length})`} loading={loading} data-sentry-element="UserListGroup" data-sentry-source-file="user-list.tsx" />
      <UserListGroup users={offlineUsers} data-testid={PlayWrightTestId.Sidebar.OFFLINE_USERS_section} label={`Offline Users (${offlineUsers.length}/${users?.length})`} loading={loading} defaultExpanded={false} data-sentry-element="UserListGroup" data-sentry-source-file="user-list.tsx" />
    </div>;
}