import { PlaywrightEnvironmentVariables } from "@/tests/constants";
import { PlaywrightUserConfiguration } from "@/tests/types";

/**
 * Playwright application user.
 */
export enum PlaywrightApplicationUser {
  ADMINISTRATOR = "ADMINISTRATOR",
  CUSTOMER_RELATION_ASSOCIATE = "CUSTOMER_RELATION_ASSOCIATE",
  CUSTOMER_RELATION_MANAGER = "CUSTOMER_RELATION_MANAGER",
  SUPER_ADMINISTRATOR = "SUPER_ADMINISTRATOR",
}

/**
 * Playwright application user configuration.
 */
export const PlaywrightApplicationUserConfiguration: {
  [key in PlaywrightApplicationUser]: PlaywrightUserConfiguration;
} = {
  /**
   * Administrator user configuration.
   */
  [PlaywrightApplicationUser.ADMINISTRATOR]: {
    contextStoragePath:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_ADMINISTRATOR_CONTEXT_STORAGE,
    email:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_ADMINISTRATOR_USER_EMAIL,
    name: "CCC Tester (Administrator)",
    password:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_ADMINISTRATOR_USER_PASSWORD,
  },
  /**
   * Customer Relation Associate user configuration.
   */
  [PlaywrightApplicationUser.CUSTOMER_RELATION_ASSOCIATE]: {
    contextStoragePath:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_CUSTOMER_RELATION_ASSOCIATE_CONTEXT_STORAGE,
    email:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_CUSTOMER_RELATION_ASSOCIATE_USER_EMAIL,
    name: "CCC Tester (Customer Relation Associate)",
    password:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_CUSTOMER_RELATION_ASSOCIATE_USER_PASSWORD,
  },
  /**
   * Customer Relation Manager user configuration.
   */
  [PlaywrightApplicationUser.CUSTOMER_RELATION_MANAGER]: {
    contextStoragePath:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_CUSTOMER_RELATION_MANAGER_CONTEXT_STORAGE,
    email:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_CUSTOMER_RELATION_MANAGER_USER_EMAIL,
    name: "CCC Tester (Customer Relation Manager)",
    password:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_CUSTOMER_RELATION_MANAGER_USER_PASSWORD,
  },
  /**
   * Super Administrator user configuration.
   */
  [PlaywrightApplicationUser.SUPER_ADMINISTRATOR]: {
    contextStoragePath:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_SUPER_ADMINISTRATOR_CONTEXT_STORAGE,
    email:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_SUPER_ADMINISTRATOR_USER_EMAIL,
    name: "CCC Tester (Super Administrator)",
    password:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_APPLICATION_SUPER_ADMINISTRATOR_USER_PASSWORD,
  },
};

/**
 * Playwright Skype user.
 */
export enum PlaywrightSkypeUser {
  DEVELOPMENT_1 = "DEVELOPMENT_1",
  DEVELOPMENT_2 = "DEVELOPMENT_2",
}

/**
 * Playwright Skype user configuration.
 */
export const PlaywrightSkypeUserConfiguration: {
  [key in PlaywrightSkypeUser]: PlaywrightUserConfiguration;
} = {
  /**
   * Development 1 user configuration.
   */
  [PlaywrightSkypeUser.DEVELOPMENT_1]: {
    contextStoragePath:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_SKYPE_DEVELOPMENT_1_CONTEXT_STORAGE,
    email:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_SKYPE_DEVELOPMENT_1_USER_EMAIL,
    name: "Skype Dev",
    password:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_SKYPE_DEVELOPMENT_1_USER_PASSWORD,
  },
  /**
   * Development 2 user configuration.
   */
  [PlaywrightSkypeUser.DEVELOPMENT_2]: {
    contextStoragePath:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_SKYPE_DEVELOPMENT_2_CONTEXT_STORAGE,
    email:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_SKYPE_DEVELOPMENT_2_USER_EMAIL,
    name: "Skype Dev 2",
    password:
      PlaywrightEnvironmentVariables.PLAYWRIGHT_SKYPE_DEVELOPMENT_2_USER_PASSWORD,
  },
};
